@font-face {
  font-family: Pixelated;
  src: url('./fonts/pixelated.ttf');
}

@font-face {
  font-family: OpenSans;
  src: url('./fonts/opensans.ttf');
}

@font-face {
  font-family: Kanit;
  src: url('./fonts/kanit.ttf');
}

@font-face {
  font-family: KanitBold;
  src: url('./fonts/kanit-bold.ttf');
}

$dark-blue: #041938;
$light-blue: #64c3fa;
$dark-yellow: #f2ac29;
$light-yellow: #fff4d1;

.App {
  text-align: center;
}
