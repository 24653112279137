@import "../App.scss";

@media (min-width: 601px) {
  .Mountains {
    width: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    z-index: 2;
  }

  .Block {
    position: relative;
    display: flex;
    justify-content: center;

    &.Sky {
      position: static;
      width: 100%;
      height: 100vh;
      max-height: 800px;
      top: 0;
      z-index: -1;
    }

    &.Work {
      color: white;
      background: $dark-blue;
    }

    &.School {
      background: $light-yellow;
      color: rgb(4, 25, 56);
      padding-bottom: 4vh;
    }

    &.Projects {
      height: 100vh;
    }

    &.Connect {
      background: $light-yellow;
      color: $dark-blue;
    }
  }

  .InnerBlock {
    width: 70%;
    margin: 5vh;

    &.Intro {
      display: inline-block;
      position: fixed;
      margin-top: 20vh;
      height: 60vh;
      z-index: 0;
    }

    &.Resume {
      width: 70%;
    }
  }

  .IntroLeft {
    width: 70%;
  }

  .IntroRight {
    width: 30%;
  }

  .Header {
    font-family: Pixelated;
    text-align: left;

    &.Title {
      padding-top: 2vw;
      padding-bottom: 4vw;
    }

    &.L {
      font-size: 5vw;
    }

    &.ML {
      font-size: 2vw;
    }

    &.M {
      font-size: 1.5vw;
    }

    &.S {
      font-size: 1vw;
    }

    &.XS {
      font-size: 0.5vw;
    }

    &.White {
      color: white;
    }

    &.Blue {
      color: $dark-blue;
    }

    &.LightBlue {
      color: $light-blue;
    }

    &.Yellow {
      color: $light-yellow;
    }

    &.Gold {
      color: $dark-yellow;
    }

    &.Right {
      text-align: right;
    }

    &.Center {
      text-align: center;
    }
  }

  .DatesBlock {
    position: relative;
    height: 100%;
  }

  .TopDate {
    position: absolute;
    top: 0.1vh;
    left: 0;
    align-items: end;
    justify-content: right;
  }

  .BottomDate {
    position: absolute;
    bottom: 0.1vh;
    left: 0;
    align-items: end;
    justify-content: right;
  }

  .VerticalBox {
    position: relative;
    width: 3vw;
  }

  .Text {
    flex: 1;
    font-family: Kanit;
    font-weight: 100;
    font-size: 1.5vw;
    text-align: left;
  }

  .Links {
    text-align: right;

    li {
      list-style-type: none;
    }

    a {
      display: inline-block;
      line-height: 2vh;
      vertical-align: middle;
      text-decoration: none;
    }
  }

  .ChainLink {
    height: 1vw;
    width: 1vw;
    background: clear;
    border: 0.5vw solid;
    z-index: 2;
  }

  .LinkLine {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .LinkLineInner {
    margin-right: 0.55vw;
    margin-top: -0.375vw;
    margin-bottom: -0.375vw;
    height: 2.5vw;
    width: 0.8vw;
    z-index: 1;
  }

  .ProjectBoxesOuter {
    height: 80vh;
    width: 100vw;
    max-width: 1200px;
    overflow: hidden;
  }

  .ProjectBox {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    font-family: KanitBold;
    font-weight: 500;
    font-size: 1.5vw;
    will-change: transform, opacity, scale;
    -webkit-user-select: none;
    user-select: none;

    &.L > div {
      position: absolute;
      background: white;
      border-radius: 1vw;
      padding-top: 2vh;
      padding-left: 1vh;
      padding-right: 1vh;
      height: 22vw;
      width: 18vw;
      left: 18vw;
      top: 6vh;
      box-shadow: 0px 0px 15px rgba(4, 25, 56, 0.5);
    }

    &.M > div {
      position: absolute;
      background: white;
      border-radius: 1vw;
      padding-top: 2vh;
      padding-left: 1vh;
      padding-right: 1vh;
      height: 22vw;
      width: 18vw;
      left: 40vw;
      top: 6vh;
      box-shadow: 0px 0px 15px rgba(4, 25, 56, 0.5);
    }

    &.R > div {
      position: absolute;
      background: white;
      border-radius: 1vw;
      padding-top: 2vh;
      padding-left: 1vh;
      padding-right: 1vh;
      height: 22vw;
      width: 18vw;
      left: 62vw;
      top: 6vh;
      box-shadow: 0px 0px 15px rgba(4, 25, 56, 0.5);
    }
  }

  .UvaLogo {
    height: 20vh;
    width: 15vh;
    padding-left: 5vh;
    background-size: cover;
    background-position: center;
  }

  .FlexRow {
    display: flex;
    flex-direction: row;

    &.Logos {
      padding-top: 3vw;
      padding-bottom: 4vw;
      padding-left: 15vw;
      padding-right: 15vw;
    }
  }

  .FlexBox {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ClickBox {
    background-size: cover;
    background-position: center;

    &.Github {
      width: 8.2vw;
      height: 8vw;
      margin-top: 0.5vw;
      box-shadow: 0px 0px 0px 0.4vh rgb(21, 24, 27) inset;
      border-radius: 8vh;
    }
    &.LinkedIn {
      width: 8vw;
      height: 8vw;
      margin-top: 0.5vw;
      box-shadow: 0px 0px 1px 0.4vh rgb(13, 94, 164) inset;
      border-radius: 2vw;
    }
    &.Gmail {
      width: 9vw;
      height: 7vw;
      margin-top: 0.5vw;
      border-radius: 1.2vw;
    }
  }

  .ClickBox:hover {
    background-color: white;
  }
}

@media (min-width: 1200px) {
  .Mountains {
    width: 100%;
    height: 30vh;
    position: absolute;
    background-size: cover;
    background-position: center;
    top: 70vh;
    z-index: 2;
  }

  .Block {
    position: relative;
    display: flex;
    justify-content: center;

    &.Sky {
      position: static;
      width: 100%;
      height: 100vh;
      top: 0;
      z-index: -1;
    }

    &.Work {
      color: white;
      background: $dark-blue;
    }

    &.School {
      background: $light-yellow;
      color: rgb(4, 25, 56);
      padding-bottom: 4vh;
    }

    &.Projects {
      height: 100vh;
    }

    &.Connect {
      background: $light-yellow;
      color: $dark-blue;
    }
  }

  .InnerBlock {
    width: 70%;
    max-width: 1200px;
    margin: 5vh;

    &.Intro {
      display: inline-block;
      position: fixed;
      margin-top: 20vh;
      height: 60vh;
      z-index: 0;
    }

    &.Resume {
      width: 70%;
    }
  }

  .IntroLeft {
    width: 70%;
  }

  .IntroRight {
    width: 30%;
  }

  .Header {
    font-family: Pixelated;
    text-align: left;

    &.Title {
      padding-top: 24px;
      padding-bottom: 48px;
    }

    &.L {
      font-size: 60px;
    }

    &.ML {
      font-size: 24px;
    }

    &.M {
      font-size: 18px;
    }

    &.S {
      font-size: 12px;
    }

    &.XS {
      font-size: 6px;
    }

    &.White {
      color: white;
    }

    &.Blue {
      color: $dark-blue;
    }

    &.LightBlue {
      color: $light-blue;
    }

    &.Yellow {
      color: $light-yellow;
    }

    &.Gold {
      color: $dark-yellow;
    }

    &.Right {
      text-align: right;
    }

    &.Center {
      text-align: center;
    }
  }

  .DatesBlock {
    position: relative;
    height: 100%;
  }

  .TopDate {
    position: absolute;
    top: 0.1vh;
    left: 0;
    align-items: end;
    justify-content: right;
  }

  .BottomDate {
    position: absolute;
    bottom: 0.1vh;
    left: 0;
    align-items: end;
    justify-content: right;
  }

  .VerticalBox {
    position: relative;
    width: 3vw;
  }

  .Text {
    flex: 1;
    font-family: Kanit;
    font-weight: 100;
    font-size: 18px;
    text-align: left;
  }

  .Links {
    text-align: right;

    li {
      list-style-type: none;
    }

    a {
      display: inline-block;
      line-height: 2vh;
      vertical-align: middle;
      text-decoration: none;
    }
  }

  .ChainLink {
    height: 12px;
    width: 12px;
    background: clear;
    border: 6px solid;
    z-index: 2;
  }

  .LinkLine {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .LinkLineInner {
    margin-right: 6.6px;
    margin-top: -4.5px;
    margin-bottom: -4.5px;
    height: 24px;
    width: 9.6px;
    z-index: 1;
  }

  .ProjectBoxesOuter {
    height: 80vh;
    width: 1200px;
    overflow: hidden;
  }

  .ProjectBox {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    font-family: KanitBold;
    font-weight: 500;
    font-size: 18px;
    will-change: transform, opacity, scale;
    -webkit-user-select: none;
    user-select: none;

    &.L > div {
      position: absolute;
      background: white;
      border-radius: 12px;
      height: 264px;
      width: 216px;
      left: 216px;
      top: 6vh;
      box-shadow: 0px 0px 15px rgba(4, 25, 56, 0.5);
    }

    &.M > div {
      position: absolute;
      background: white;
      border-radius: 12px;
      height: 264px;
      width: 216px;
      left: 480px;
      top: 6vh;
      box-shadow: 0px 0px 15px rgba(4, 25, 56, 0.5);
    }

    &.R > div {
      position: absolute;
      background: white;
      border-radius: 12px;
      height: 264px;
      width: 216px;
      left: 744px;
      top: 6vh;
      box-shadow: 0px 0px 15px rgba(4, 25, 56, 0.5);
    }
  }

  .UvaLogo {
    height: 20vh;
    width: 15vh;
    padding-left: 5vh;
    background-size: cover;
    background-position: center;
  }

  .FlexRow {
    display: flex;
    flex-direction: row;

    &.Logos {
      padding-top: 36px;
      padding-bottom: 48px;
      padding-left: 180px;
      padding-right: 180px;
    }
  }

  .FlexBox {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ClickBox {
    background-size: cover;
    background-position: center;

    &.Github {
      width: 98.4px;
      height: 96px;
      margin-top: 6px;
      box-shadow: 0px 0px 0px 0.4vh rgb(21, 24, 27) inset;
      border-radius: 96px;
    }
    &.LinkedIn {
      width: 96px;
      height: 96px;
      margin-top: 6px;
      box-shadow: 0px 0px 1px 0.4vh rgb(13, 94, 164) inset;
      border-radius: 24px;
    }
    &.Gmail {
      width: 108px;
      height: 84px;
      margin-top: 6px;
      border-radius: 14.4px;
    }
  }

  .ClickBox:hover {
    background-color: white;
  }
}

// Small screens (mobile)
@media (max-width: 600px) {
  .Mountains {
    width: 100%;
    height: 20vh;
    position: absolute;
    background-size: cover;
    background-position: center;
    top: 40vh;
    z-index: 2;
  }

  .Block {
    position: relative;
    display: flex;
    justify-content: center;

    &.Sky {
      position: static;
      width: 100%;
      height: 60vh;
      top: 0;
      z-index: -1;
    }

    &.Work {
      color: white;
      background: $dark-blue;
    }

    &.School {
      background: $light-yellow;
      color: $dark-blue;
    }

    &.Connect {
      background: $light-blue;
      color: $dark-blue;
    }

    &.Projects {
      display: none;
    }
  }

  .InnerBlock {
    width: 90%;
    margin: 5vh;

    &.Intro {
      display: inline-block;
      position: fixed;
      margin-top: 15vh;
      height: 60vh;
      z-index: 0;
    }
  }

  .IntroLeft {
    width: 100%;
  }

  .IntroRight {
    width: 0;
  }

  .Header {
    font-family: Pixelated;
    text-align: left;

    &.Title {
      padding-bottom: 4vw;
    }

    &.L {
      font-size: 8vw;
    }

    &.ML {
      font-size: 3vw;
    }

    &.M {
      font-size: 2.5vw;
    }

    &.S {
      font-size: 2vw;
    }

    &.White {
      color: white;
    }

    &.Blue {
      color: $dark-blue;
    }

    &.LightBlue {
      color: $light-blue;
    }

    &.Yellow {
      color: $light-yellow;
    }

    &.Gold {
      color: $dark-yellow;
    }

    &.Right {
      text-align: right;
    }

    &.Center {
      text-align: center;
    }
  }

  .Text {
    font-family: Kanit;
    font-size: 2vw;
    text-align: left;
  }

  .Links {
    display: none;
  }

  .LinkLine {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .UvaLogo {
    display: none;
  }

  .VerticalBox {
    display: none;
  }

  .FlexRow {
    display: flex;
    flex-direction: row;

    &.Logos {
      padding-top: 3vw;
      padding-bottom: 4vw;
      padding-left: 1vw;
      padding-right: 1vw;
    }
  }

  .FlexBox {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ClickBox {
    background-size: cover;
    background-position: center;

    &.Github {
      width: 8.2vw;
      height: 8vw;
      margin-top: 0.5vw;
      border-radius: 8vh;
    }
    &.LinkedIn {
      width: 8vw;
      height: 8vw;
      margin-top: 0.5vw;
      border-radius: 2vw;
    }
    &.Gmail {
      width: 9vw;
      height: 7vw;
      margin-top: 0.5vw;
      border-radius: 1.2vw;
    }
  }

  .ClickBox:hover {
    background-color: white;
  }
}
